import React, { useState, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Remarkable } from "remarkable";
import classnames from "classnames";
import { useCarousel } from "../../hooks/useCarousel";
import Media, { useMedia } from "react-media";

import Transition from "../Transition";

import ChevronRight from "../../assets/svg/ch-right-small.inline.svg";
import ChevronLeft from "../../assets/svg/ch-left-small.inline.svg";

import {
  ProductWrap,
  ProductMain,
  ProductThumbs,
  ProductInfo,
  prodTitle,
  prodDesc,
  prodThumb,
  thumbsContainer,
  carouselArrow,
  thumbHint,
} from "./Components";

const md = new Remarkable({ html: true, breaks: true });

const ProductImage = (props) => (
  <GatsbyImage
    image={props.image}
    style={{
      textAlign: 'right',
      // maxWidth:
      //   currProducts[currId].image.localFile.childImageSharp.prodImage
      //     .presentationWidth,
      width: 53 * props.image.aspectRatio + "vh",
      height: "100%",
    }}
    imgStyle={{ objectFit: "contain" }}
    objectFit="contain"
    alt={props.alt} />
);

const ProductTitle = (props) => (
  <p css={[prodTitle, { color: props.prodTextColor }]}>{props.sku}</p>
);

const ProductDesc = (props) => (
  <div
    className="prod-description"
    css={[prodDesc, { color: props.prodTextColor }]}
    dangerouslySetInnerHTML={{
      __html: md.render(props.description),
    }}
  />
);

const BrandSlider = (props) => {
  const { products, prodRange, prodTextColor, brandState } = props;
  const [currProdId, setCurrProdId] = useState(0);
  const [slideOptions, setSlideOptions] = useState({});
  const currProducts = products.filter((el) => el.product_range === prodRange);
  const isXs = useMedia({ query: "(max-width: 400px)" });
  const isMobile = useMedia({
    query: "(max-width: 768px)",
  });

  const currId = currProducts[currProdId] ? currProdId : 0;

  useEffect(() => {
    setCurrProdId(0);
  }, [brandState]);

  const {
    reset,
    sliderActive,
    maxSlide,
    active,
    setActive,
    currShownSlides,
    handlers,
    style,
    slideStyle,
  } = useCarousel(slideOptions, false);

  useEffect(() => {
    reset();
    setCurrProdId(0);
    setSlideOptions({
      slidesToShow: 6,
      length: currProducts.length,
      margin: 15,
      infinite: false,
      allowSwiping: true,
      responsive: [
        {
          breakpoint: 1200,
          options: {
            autoMargin: false,
          },
        },
        {
          breakpoint: 700,
          options: {
            slidesToShow: 3,
          },
        },
      ],
    });
  }, [prodRange, currProducts.length]);

  const onClickProdThumb = (e, index) => {
    if (active === maxSlide) {
      if (active === index) {
        setActive(active - 1);
      }
    } else if (active === 0) {
      if (index === currShownSlides - 1) {
        setActive(active + 1);
      }
    } else {
      if (index === currShownSlides - 1 + active) {
        setActive(active + 1);
      } else if (index === active) {
        setActive(active - 1);
      }
    }
    setCurrProdId(index);
  };

  const slideToLeft = () => {
    active > 0 && setActive(active - 1);
  };

  const slideToRight = () => {
    active < slideOptions.length - 1 && setActive(active + 1);
  };

  const productTransitionProps = !isMobile
    ? {
      position: "relative",
    }
    : { position: "absolute" };
  const productExitingProps = !isMobile
    ? {
      position: "absolute",
    }
    : {};

  if (isXs) {
    productTransitionProps.top = "74px";
  } else {
    productTransitionProps.left = 0;
  }

  console.log(currProdId)

  return (
    <Transition timeout={300} keyProp={prodRange} type="fadeFully">
      <ProductWrap className="hide-on-expanded product-wrap">
        <ProductMain className="product-main">
          <Media
            queries={{ mobile: { maxWidth: 400 } }}
            defaultMatches={{ mobile: false }}
          >
            {(matches) =>
              matches.mobile ? (
                <>
                  <Transition
                    timeout={300}
                    keyProp={currProdId}
                    type="fadeFully"
                  >
                    <ProductTitle
                      prodTextColor={prodTextColor}
                      sku={currProducts[currId].sku}
                    />
                  </Transition>
                  <Transition
                    timeout={300}
                    keyProp={currProdId}
                    enteringProps={productTransitionProps}
                  >
                    {currProducts[currId].image && (
                      <ProductImage
                        image={currProducts[currId].image.localFile.childImageSharp.prodImage}
                        alt={currProducts[currId].image.alternativeText || ''}
                      />
                    )}
                  </Transition>
                  <Transition
                    timeout={300}
                    keyProp={currProdId}
                    type="fadeFully"
                  >
                    <ProductDesc
                      prodTextColor={prodTextColor}
                      description={currProducts[currId].description}
                    />
                  </Transition>
                </>
              ) : (
                <>
                  <Transition
                    timeout={800}
                    keyProp={currProdId}
                    globalStyle={{ opacity: 0 }}
                    enteringProps={productTransitionProps}
                    exitingProps={productExitingProps}
                  >
                    {currProducts[currId].image && (
                      <ProductImage
                        image={currProducts[currId].image.localFile.childImageSharp.prodImage}
                        alt={currProducts[currId].image.alternativeText || ''}
                      />
                    )}
                  </Transition>
                  <ProductInfo className="product-info">
                    <Transition
                      timeout={300}
                      keyProp={currProdId}
                      type="fadeFully"
                    >
                      <ProductTitle
                        prodTextColor={prodTextColor}
                        sku={currProducts[currId].sku}
                      />
                      <ProductDesc
                        prodTextColor={prodTextColor}
                        description={currProducts[currId].description}
                      />
                    </Transition>
                  </ProductInfo>
                </>
              )
            }
          </Media>
        </ProductMain>
        <ProductThumbs className="product-thumbs">
          {sliderActive && (
            <>
              <button
                css={[
                  carouselArrow({ pos: "left" }),
                  { opacity: active === 0 ? "0" : "1" },
                ]}
                onClick={slideToLeft}
              >
                <ChevronLeft fill={prodTextColor} />
              </button>
              <button
                css={[
                  carouselArrow({ pos: "right" }),
                  {
                    opacity: active === maxSlide ? "0" : "1",
                  },
                ]}
                onClick={slideToRight}
              >
                <ChevronRight fill={prodTextColor} />
              </button>
            </>
          )}
          <div css={{ overflow: "hidden" }}>
            <div css={thumbsContainer} {...handlers} style={style}>
              {currProducts.map((el, index) => (
                <div
                  role="presentation"
                  key={index}
                  css={prodThumb}
                  onClick={(e) => onClickProdThumb(e, index)}
                  className={classnames({ active: index === currId })}
                  style={slideStyle}
                >
                  {el.image ? (
                    <GatsbyImage
                      image={el.image.localFile.childImageSharp.prodImage}
                      alt={el.image.alternativeText || ''}
                      style={{ width: "100%" }}
                      imgStyle={{ objectFit: "contain" }}
                      draggable={false}
                      loading="eager" />
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </div>
          <div
            css={{
              position: "absolute",
              top: "100%",
              left: "0",
              right: "0",
              textAlign: "center",
            }}
          >
            <p css={thumbHint({ color: prodTextColor })}>
              Click on a pack for more information
            </p>
          </div>
        </ProductThumbs>
      </ProductWrap>
    </Transition>
  );
};

export default BrandSlider;