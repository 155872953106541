import React from "react";
import toCamelCase from "../../utils/camelCase";
import { GatsbyImage } from "gatsby-plugin-image";

const backgrounds = {
  king: (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1362.69 768.72"
    >
      <defs>
        <linearGradient
          id="silver-white-grad"
          x1="58.59"
          y1="-112.5"
          x2="980.06"
          y2="622.69"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".02" stopColor="#bcc0c1" />
          <stop offset=".04" stopColor="#aaafb0" />
          <stop offset=".08" stopColor="#949a9c" />
          <stop offset=".1" stopColor="#8c9295" />
          <stop offset=".14" stopColor="#adb1b3" />
          <stop offset=".2" stopColor="#d0d3d4" />
          <stop offset=".25" stopColor="#eaebec" />
          <stop offset=".29" stopColor="#fafafa" />
          <stop offset=".33" stopColor="#fff" />
          <stop offset=".39" stopColor="#eaebeb" />
          <stop offset=".51" stopColor="#b4b7b9" />
          <stop offset=".58" stopColor="#969b9d" />
          <stop offset=".68" stopColor="#868788" />
          <stop offset=".75" stopColor="gray" />
          <stop offset=".81" stopColor="#888989" />
          <stop offset=".9" stopColor="#9fa1a1" />
          <stop offset="1" stopColor="#bcc0c1" />
        </linearGradient>
      </defs>
      <path fill="url(#silver-white-grad)" d="M0 0h1362.69v768.72H0z" />
    </svg>
  ),
  corset: (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1362.69 768.72"
    >
      <defs>
        <linearGradient
          id="silver-white-grad"
          x1="58.59"
          y1="-112.5"
          x2="980.06"
          y2="622.69"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".02" stopColor="#bcc0c1" />
          <stop offset=".04" stopColor="#aaafb0" />
          <stop offset=".08" stopColor="#949a9c" />
          <stop offset=".1" stopColor="#8c9295" />
          <stop offset=".14" stopColor="#adb1b3" />
          <stop offset=".2" stopColor="#d0d3d4" />
          <stop offset=".25" stopColor="#eaebec" />
          <stop offset=".29" stopColor="#fafafa" />
          <stop offset=".33" stopColor="#fff" />
          <stop offset=".39" stopColor="#eaebeb" />
          <stop offset=".51" stopColor="#b4b7b9" />
          <stop offset=".58" stopColor="#969b9d" />
          <stop offset=".68" stopColor="#868788" />
          <stop offset=".75" stopColor="gray" />
          <stop offset=".81" stopColor="#888989" />
          <stop offset=".9" stopColor="#9fa1a1" />
          <stop offset="1" stopColor="#bcc0c1" />
        </linearGradient>
      </defs>
      <path fill="url(#silver-white-grad)" d="M0 0h1362.69v768.72H0z" />
    </svg>
  ),
  merilyn: (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1362.69 768.72"
    >
      <defs>
        <linearGradient
          id="silver-white-grad"
          x1="58.59"
          y1="-112.5"
          x2="980.06"
          y2="622.69"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".02" stopColor="#bcc0c1" />
          <stop offset=".04" stopColor="#aaafb0" />
          <stop offset=".08" stopColor="#949a9c" />
          <stop offset=".1" stopColor="#8c9295" />
          <stop offset=".14" stopColor="#adb1b3" />
          <stop offset=".2" stopColor="#d0d3d4" />
          <stop offset=".25" stopColor="#eaebec" />
          <stop offset=".29" stopColor="#fafafa" />
          <stop offset=".33" stopColor="#fff" />
          <stop offset=".39" stopColor="#eaebeb" />
          <stop offset=".51" stopColor="#b4b7b9" />
          <stop offset=".58" stopColor="#969b9d" />
          <stop offset=".68" stopColor="#868788" />
          <stop offset=".75" stopColor="gray" />
          <stop offset=".81" stopColor="#888989" />
          <stop offset=".9" stopColor="#9fa1a1" />
          <stop offset="1" stopColor="#bcc0c1" />
        </linearGradient>
      </defs>
      <path fill="url(#silver-white-grad)" d="M0 0h1362.69v768.72H0z" />
    </svg>
  ),
  corsair: (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1362.69 768.72"
    >
      <defs>
        <linearGradient
          id="silver-white-grad"
          x1="58.59"
          y1="-112.5"
          x2="980.06"
          y2="622.69"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".02" stopColor="#bcc0c1" />
          <stop offset=".04" stopColor="#aaafb0" />
          <stop offset=".08" stopColor="#949a9c" />
          <stop offset=".1" stopColor="#8c9295" />
          <stop offset=".14" stopColor="#adb1b3" />
          <stop offset=".2" stopColor="#d0d3d4" />
          <stop offset=".25" stopColor="#eaebec" />
          <stop offset=".29" stopColor="#fafafa" />
          <stop offset=".33" stopColor="#fff" />
          <stop offset=".39" stopColor="#eaebeb" />
          <stop offset=".51" stopColor="#b4b7b9" />
          <stop offset=".58" stopColor="#969b9d" />
          <stop offset=".68" stopColor="#868788" />
          <stop offset=".75" stopColor="gray" />
          <stop offset=".81" stopColor="#888989" />
          <stop offset=".9" stopColor="#9fa1a1" />
          <stop offset="1" stopColor="#bcc0c1" />
        </linearGradient>
      </defs>
      <path fill="url(#silver-white-grad)" d="M0 0h1362.69v768.72H0z" />
    </svg>
  ),
  bestMan: (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 1083.12"
    >
      <defs>
        <linearGradient
          id="bestman-grad"
          x1="82.56"
          x2="1380.89"
          y1="-158.51"
          y2="877.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#00214b" />
          <stop offset="1" stopColor="#0d67aa" />
        </linearGradient>
      </defs>
      <path fill="url(#bestman-grad)" d="M0 0h1920v1083.12H0z" />
    </svg>
  ),
};

const BrandBackground = ({ rowBackground, imgBackground }) => (
  <div
    css={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }}
  >
    {imgBackground ? (
      <GatsbyImage
        image={imgBackground.localFile.childImageSharp.gatsbyImageData}
        alt={imgBackground.alternativeText || ''}
        css={{ height: "100%", width: "100%" }} />
    ) : (
      backgrounds[toCamelCase(rowBackground)]
    )}
  </div>
);

export default BrandBackground;