import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const ProductWrap = styled.div`
  position: absolute;
  top: 18%;
  bottom: 5%;
  left: 5%;
  right: 38%;
  display: flex;
  flex-flow: column;
  align-items: center;
  @media (max-width: 1200px) {
    right: 520px;
    .product-main {
      margin-right: 160px;
    }
    .prod-description {
      font-size: 16px;
    }
    .product-info {
      left: calc(100% + 10px);
      width: calc(188px + (100vw - 950px) * 0.168);
    }
    .product-thumbs {
      width: 530px;
    }
  }
  @media (max-width: 1200px) {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    .product-info {
      left: calc(100% + 20px);
      width: 300px;
    }
    .product-thumbs {
      width: calc(100% - 100px);
    }
  }
  @media (max-width: 768px) {
    .product-main {
      margin-right: 0;
      justify-content: center;
      align-items: center;
    }
    .product-info {
      position: relative;
      left: initial;
      top: initial;
      transform: none;
      width: 39vw;
      margin-left: 20px;
    }
  }
  @media (max-width: 700px) {
    .product-thumbs {
      width: 300px;
    }
  }
  @media (max-width: 600px) {
    flex-flow: column-reverse;
  }
  @media (max-width: 400px) {
    .product-thumbs {
      width: 240px;
      margin-bottom: 0;
    }
  }
`;

export const ProductMain = styled.div`
  position: relative;
  height: 53vh;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1366px) and (max-height: 840px) {
    .gatsby-image-wrapper img {
      max-height: 50vh;
    }
  }
`;

export const ProductInfo = styled.div`
  position: absolute;
  left: calc(100% + 25px);
  top: 50%;
  transform: translateY(-50%);
  width: 18vw;
`;

export const prodTitle = css`
  color: #000;
  font-family: var(--sec-font);
  font-size: calc(24px + (100vw - 1366px) * 0.011);
  font-weight: bold;
  text-transform: uppercase;
  @media (max-width: 1366px) {
    font-size: 24px;
  }
  @media (max-width: 400px) {
    margin: 0 auto 20px;
    min-height: 54px;
    display: flex;
    align-items: center;
    text-align: center;
  }
`;

export const prodDesc = css`
  color: #000;
  line-height: 1.35;
  font-size: calc(16px + (100vw - 1366px) * 0.0072);
`;

export const ProductThumbs = styled.div`
  width: 100%;
  max-width: 645px;
  width: 645px;
  position: relative;
  margin-bottom: 25px;
`;

export const thumbsContainer = css`
  display: flex;
  padding: 20px 0;
  justify-content: center;
  @media (max-width: 600px) {
    padding: 8px 0;
  }
`;

export const prodThumb = css`
  width: 95px;
  height: 95px;
  margin: 0 8px;
  user-select: none;
  img {
    user-drag: none;
  }
  border: 2px solid #fff;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: rgba(255, 255, 255, 0.34);
  display: flex;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  &.active {
    background-color: rgba(255, 255, 255, 1);
  }
`;

export const carouselArrow = (props) => css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  transition: opacity 0.2s;
  padding: 0;
  width: 36px;
  padding: 8px;
  ${props.pos === "left" && "right: calc(100% + 5px)"}
  ${props.pos === "right" && "left: calc(100% + 5px)"}
`;

export const thumbHint = (props) => css`
  margin: 0;
  color: ${props.color};
  @media (max-width: 400px) {
    display: none;
  }
`;
